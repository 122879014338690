import { useContext } from 'react';
import { CookiePolicyContext } from '../contexts';

interface ICookiePolicy {
  isShowCookiePolicyPopup: boolean;
  show(): void;
  close(): void;
  openOneTrustDialog(): void;
}

export const useCookiePolicy = (): ICookiePolicy => {
  return useContext(CookiePolicyContext);
};
