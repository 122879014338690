export const isSafari = (): boolean => {
  if (typeof window !== 'undefined')
    return (
      // @ts-ignore
      /constructor/i.test(window.HTMLElement) ||
      (function (p): boolean {
        return p.toString() === '[object' + ' SafariRemoteNotification]';
        // @ts-ignore @no-undef
        // eslint-disable-next-line no-undef
      })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification))
    );
  return false;
};
