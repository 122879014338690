import { FC, useEffect, useRef, useState, CSSProperties } from 'react';
import type { LottieComponentProps } from 'lottie-react';
import styles from './styles.module.scss';
import Image, { ImageProps, StaticImageData } from 'next/image';
import dynamic from 'next/dynamic';
import { Box } from '@mui/material';
import clsx from 'clsx';
import { LottieRefCurrentProps } from 'lottie-react';
import { useInView } from 'react-intersection-observer';
import { isMobile } from '@/libs/isMobile';
import { isSafari } from '@/libs/isSafari';

type LazyLottieProps = Omit<LottieComponentProps, 'animationData'> & {
  dataSrc: string;
  previewProps?: Omit<ImageProps, 'src'>;
  previewSrc: string | StaticImageData;
  animationStyles?: CSSProperties;
};

type DataStateType = {
  isLoading: boolean;
  data: JSON | null;
  error: boolean;
};

const DynamicLottie = dynamic(() => import('lottie-react'), {
  ssr: false,
});

export const LazyLottie: FC<LazyLottieProps> = ({
  dataSrc,
  loop = false,
  autoplay = true,
  previewSrc,
  previewProps = {},
  animationStyles,
  ...restProps
}) => {
  const [dataState, setDataState] = useState<DataStateType>({
    isLoading: true,
    data: null,
    error: false,
  });
  const hidePreview = Boolean(dataState.data);
  const { ref, inView } = useInView({
    threshold: 0,
  });
  const lottieRef = useRef<LottieRefCurrentProps>(null);
  const animationIsAvailable =
    typeof window !== 'undefined' && !isMobile() && !isSafari();

  useEffect(() => {
    if (!animationIsAvailable) return;

    const loadAnimationData = async (): Promise<void> => {
      try {
        const response = await fetch(dataSrc);
        const data = await response.json();
        setDataState({
          isLoading: false,
          data,
          error: false,
        });
      } catch (error) {
        setDataState({
          isLoading: false,
          data: null,
          error: true,
        });
      }
    };

    const timeout = setTimeout(() => {
      void loadAnimationData();
    }, 750);

    return (): void => {
      clearTimeout(timeout);
    };
  }, [dataSrc, animationIsAvailable]);

  useEffect(() => {
    if (inView && hidePreview) {
      lottieRef?.current?.play();
    } else {
      lottieRef?.current?.pause();
    }
  }, [inView, hidePreview, animationIsAvailable]);

  return (
    <Box className={styles.wrapper} ref={ref}>
      <Image
        {...previewProps}
        src={previewSrc}
        alt={previewProps?.alt || ''}
        className={clsx(
          styles.image,
          {
            [styles.invisible]: hidePreview,
          },
          previewProps.className && previewProps.className,
        )}
      />
      {animationIsAvailable && (
        <DynamicLottie
          lottieRef={lottieRef}
          className={styles.lottie}
          loop={loop}
          renderer="svg"
          style={animationStyles}
          autoplay={autoplay}
          animationData={dataState.data}
          rendererSettings={{
            preserveAspectRatio: 'xMidYMid slice',
          }}
          {...restProps}
        />
      )}
    </Box>
  );
};
