import { HttpClientError } from './HttpClientError';

export interface IHttpClient {
  get: <T>(url: string) => Promise<T>;
  post: <T>(url: string, params: { [key: string]: unknown }) => Promise<T>;
}

class HttpClient implements IHttpClient {
  private baseUrl = `${process.env.NEXT_PUBLIC_API}/api/v1`;

  private buildPath(url: string): string {
    const externalUrlRegex =
      /^(https?:\/\/)?([a-zA-Z0-9-]+\.){1,}([a-zA-Z]{2,})(\/[^\s]*)?$/;

    if (externalUrlRegex.test(url)) {
      return url;
    }

    return `${this.baseUrl}${url}`;
  }

  async get<T>(url: string, options?: { headers?: HeadersInit }): Promise<T> {
    const response = await fetch(this.buildPath(url), {
      headers: {
        'Content-Type': 'application/json',
        ...(options?.headers ?? {}),
      },
    });

    if (!response.ok) {
      throw new HttpClientError('Bad GET response', response);
    }
    return response.json();
  }

  async post<T>(url: string, params: unknown): Promise<T> {
    const response = await fetch(this.buildPath(url), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });
    if (!response.ok) {
      throw new HttpClientError('Bad POST response', response);
    }
    return response.json();
  }
}

const httpClientInstance = new HttpClient();

export { httpClientInstance as httpClient };
