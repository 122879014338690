import { SyntheticEvent } from 'react';
import { httpClient } from '@/libs/api';
import { useTranslation } from 'react-i18next';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { UseFormReturn, useForm } from 'react-hook-form';
import { useAnalytics } from '@/libs/analytics';

type FormValues = {
  email: string;
};

type Form = UseFormReturn<FormValues>;

type UseStartFreeForm = () => Pick<Form, 'control'> & {
  isValid: Form['formState']['isValid'];
  errors: Form['formState']['errors'];
  handleSubmit: (e: SyntheticEvent) => void;
};

export const useStartFreeForm: UseStartFreeForm = () => {
  const { t } = useTranslation('common');
  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    getValues,
    setError,
  } = useForm<FormValues>({
    mode: 'onBlur',
    resolver: yupResolver(
      object().shape({
        email: string().email(t('validation.email')).required(t('validation.required')),
      }),
    ),
  });
  const { email } = getValues();
  const { startForFreeClicked } = useAnalytics();
  const onSubmit = (e: SyntheticEvent): void => {
    e.preventDefault();
    void handleSubmit(async () => {
      try {
        const searchParams = new URLSearchParams({ email });
        const isValid = await httpClient.get<boolean>(
          `/account/validate-email?${searchParams.toString()}`,
        );
        if (!isValid) {
          setError('email', {
            message: t('validation.workEmail'),
          });
          return;
        }
        const isExist = await httpClient.get<boolean>(
          `/account/check?${searchParams.toString()}`,
        );
        if (isExist) {
          setError('email', {
            message: t('startFreeForm.errors.userExist'),
          });
          return;
        }
        startForFreeClicked();
        window.location.assign(
          `${process.env.NEXT_PUBLIC_DASHBOARD_URL}/signup?email=${btoa(email)}`,
        );
      } catch (error) {
        console.error(error);
      }
    })();
  };

  return {
    control,
    errors,
    isValid,
    handleSubmit: onSubmit,
  };
};
