import { FC } from 'react';
import { Button, Modal } from '@mui/material';
import styles from './styles.module.scss';
import { Container } from '@/components';
import CloseIcon from '@/assets/icons/close-round.svg';
import dynamic from 'next/dynamic';

type VideoModal = {
  videoSrc: string;
  onClose: () => void;
  open: boolean;
  vimeoSrc?: string;
};
const PlayerDynamic = dynamic(
  () => import('@/components/Player').then((mod) => mod.Player),
  { ssr: false },
);

export const VideoModal: FC<VideoModal> = ({ onClose, open, videoSrc, vimeoSrc }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="video-modal"
      className={styles.wrapper}
      closeAfterTransition
    >
      <Container className={styles.container}>
        <>
          <Button className={styles.close} onClick={onClose}>
            <CloseIcon />
          </Button>
          {open && <PlayerDynamic url={videoSrc} vimeoUrl={vimeoSrc} />}
        </>
      </Container>
    </Modal>
  );
};
