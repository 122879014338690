import React, { PropsWithChildren, useContext } from 'react';
import { HeaderContext, NavChildOption } from '../../context';
import { Box, Container, List, ListItem, Typography } from '@mui/material';
import styles from './styles.module.scss';
import clsx from 'clsx';
import Link from 'next/link';

export const HeaderSubNav: React.FC<PropsWithChildren<{ items: NavChildOption[] }>> = ({
  items,
}) => {
  const { setOpenedSubMenu, setMenuIsOpen } = useContext(HeaderContext);

  const handleClick = (e: React.MouseEvent, item: NavChildOption): void => {
    if (!item.link) {
      e.preventDefault();
    }
    item.onClick && item.onClick();
    setOpenedSubMenu(null);
    setMenuIsOpen(false);
  };

  return (
    <Box className={clsx(styles.subNav, 'subnav')}>
      <Container className={styles.subNavContainer}>
        <List className={styles.subNavList}>
          {items.map((item) => {
            const IconComponent = item.icon;
            return (
              <ListItem key={item.id} className={styles.subNavListItem}>
                <Link
                  href={item.link || '#'}
                  target={item.target || '_self'}
                  onClick={(e): void => {
                    handleClick(e, item);
                  }}
                  className={styles.subNavItem}
                >
                  <Box className={styles.subNavItemIcon}>
                    <IconComponent />
                  </Box>
                  <Box>
                    <Typography variant="subtitle2" className={styles.subNavItemTitle}>
                      {item.title}
                    </Typography>
                    <Typography variant="body2" className={styles.subNavItemDesc}>
                      {item.desc}
                    </Typography>
                  </Box>
                </Link>
              </ListItem>
            );
          })}
        </List>
      </Container>
    </Box>
  );
};
