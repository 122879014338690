import { AMPLITUDE_EVENTS, Amplitude, IAmplitude } from './Amplitude';
import { fbq } from './Facebook';

interface IAnalytics {
  startForFreeClicked: () => void;
  requestDemoClicked: () => void;
  chromeExtensionClicked: () => void;
  pricingPageMonthlyClicked: () => void;
  pricingPageAnnualClicked: () => void;
  pricingPageGetStartedClicked: () => void;
  pricingPageTalkToSalesClicked: () => void;
  bookDemoClicked: () => void;
}

class Analytics implements IAnalytics {
  constructor(private readonly amplitude: IAmplitude = new Amplitude()) {}

  startForFreeClicked = (): void => {
    this.amplitude.trackEvent(AMPLITUDE_EVENTS.START_FOR_FREE_CLICKED);
  };

  requestDemoClicked = (): void => {
    fbq('track', 'Contact');
    this.amplitude.trackEvent(AMPLITUDE_EVENTS.REQUEST_DEMO_CLICKED);
  };

  chromeExtensionClicked = (): void => {
    this.amplitude.trackEvent(AMPLITUDE_EVENTS.CHROME_EXTENSION_CLICKED);
  };

  pricingPageMonthlyClicked = (): void => {
    this.amplitude.trackEvent(AMPLITUDE_EVENTS.PRICING_PAGE.MONTHLY_CLICKED);
  };

  pricingPageAnnualClicked = (): void => {
    this.amplitude.trackEvent(AMPLITUDE_EVENTS.PRICING_PAGE.ANNUAL_CLICKED);
  };

  pricingPageGetStartedClicked = (): void => {
    this.amplitude.trackEvent(AMPLITUDE_EVENTS.PRICING_PAGE.GET_STARTED_CLICKED);
  };

  pricingPageTalkToSalesClicked = (): void => {
    this.amplitude.trackEvent(AMPLITUDE_EVENTS.PRICING_PAGE.TALK_TO_SALES_CLICKED);
  };

  bookDemoClicked = (): void => {
    this.amplitude.trackEvent(AMPLITUDE_EVENTS.BOOK_DEMO_CLICKED);
  };
}

export const AnalyticInstance = new Analytics();
