import { FC } from 'react';
import Script from 'next/script';
import { hubspotID } from './config';

export const HubspotPixel: FC<{ id?: string }> = ({ id = hubspotID }) => (
  <Script
    strategy="lazyOnload"
    type="text/javascript"
    id="hs-script-loader"
    src={`//js.hs-scripts.com/${id}.js`}
  />
);
