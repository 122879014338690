import { Box, BoxProps } from '@mui/material';
import styles from './stylesRound.module.scss';
import { FC } from 'react';
import clsx from 'clsx';

export const RoundBackground: FC<BoxProps> = ({ ...props }) => {
  return (
    <Box {...props} className={clsx(styles.roundBackgroundRoot, props.className)}>
      <Box className={clsx(styles.circleLarge, styles.circle)}></Box>
      <Box className={clsx(styles.circleSmall, styles.circle)}></Box>
    </Box>
  );
};
