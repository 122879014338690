import { Box, Link as MuiLink, List, ListItem, Typography } from '@mui/material';
import { FC } from 'react';
import { useFooterNav } from './hooks';
import TwitterIcon from '@/assets/icons/Twitter.svg';
import FacebookIcon from '@/assets/icons/Facebook.svg';
import LinkedinIcon from '@/assets/icons/Linkedin.svg';
import ChromeExtensionIcon from '@/assets/icons/ChromeExtension.svg';
import { Container } from '../Container';
import styles from './styles.module.scss';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { EXTERNAL_ROUTES, ROUTES } from '@/libs/routing';
import { useCookiePolicy } from '..';
import { useAnalytics } from '@/libs/analytics';
import LogoGradient from '@/assets/icons/LogoGradient.svg';

export const Footer: FC = () => {
  const { config } = useFooterNav();
  const { t } = useTranslation('common');
  const { openOneTrustDialog } = useCookiePolicy();
  const { chromeExtensionClicked } = useAnalytics();

  return (
    <Box component="footer" className={styles.footer}>
      <Container>
        <Box className={styles.head}>
          <Link href="/" className={styles.logo}>
            <LogoGradient />
          </Link>
          <Box className={styles.social}>
            <MuiLink
              href={EXTERNAL_ROUTES.SOCIAL.TWITTER}
              target="_blank"
              className={styles.socialLink}
            >
              <TwitterIcon />
            </MuiLink>
            <MuiLink
              href={EXTERNAL_ROUTES.SOCIAL.FACEBOOK}
              target="_blank"
              className={styles.socialLink}
            >
              <FacebookIcon />
            </MuiLink>
            <MuiLink
              href={EXTERNAL_ROUTES.SOCIAL.LINKEDIN}
              target="_blank"
              className={styles.socialLink}
            >
              <LinkedinIcon />
            </MuiLink>
          </Box>
        </Box>
        <Box className={styles.nav}>
          {config.map((item) => {
            return (
              <Box className={styles.navItem} key={item.label}>
                <Typography className={styles.navLabel}>{item.label}</Typography>
                <List className={styles.navList}>
                  {item.children.map((inner, index) => {
                    return (
                      <ListItem
                        key={`${item.label + index}`}
                        className={styles.navListItem}
                      >
                        <Link
                          href={inner.link || '#'}
                          className={styles.navLink}
                          {...(Boolean(inner?.target) && {
                            target: inner.target,
                          })}
                        >
                          <span onClick={inner?.onClick}>{inner.label}</span>
                        </Link>
                      </ListItem>
                    );
                  })}
                </List>
              </Box>
            );
          })}
          <Box className={styles.navItem}>
            <Typography className={styles.navLabel}>
              {t('footer.extensionInfo.title')}
            </Typography>
            <Typography className={styles.navDesc}>
              {t('footer.extensionInfo.desc')}
            </Typography>
            <MuiLink
              href={EXTERNAL_ROUTES.EXTENSION_MARKET}
              target="_blank"
              className={styles.navIcoLink}
            >
              <ChromeExtensionIcon onClick={chromeExtensionClicked} />
            </MuiLink>
          </Box>
        </Box>
        <Box className={styles.bottomInfo}>
          <Typography variant="body2" className={styles.disclaimer}>
            {t('footer.disclaimer', { year: new Date().getFullYear() })}
          </Typography>
          <Box className={styles.bottomInfoLinks}>
            <MuiLink href={ROUTES.PRIVACY_POLICY} className={styles.bottomInfoLink}>
              {t('footer.bottomInfo.privacyPolicy')}
            </MuiLink>
            <MuiLink href={ROUTES.TERMS_OF_USE} className={styles.bottomInfoLink}>
              {t('footer.bottomInfo.terms')}
            </MuiLink>
            <MuiLink className={styles.bottomInfoLink} onClick={openOneTrustDialog}>
              {t('footer.bottomInfo.cookiePolicy')}
            </MuiLink>
            <MuiLink href={ROUTES.OPT_OUT} className={styles.bottomInfoLink}>
              {t('footer.bottomInfo.infoSellPrevent')}
            </MuiLink>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
