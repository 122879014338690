import { Box, BoxProps, Typography, TypographyVariant } from '@mui/material';
import { FC, ReactElement } from 'react';
import styles from './styles.module.scss';
import clsx from 'clsx';
import { Trans } from 'next-i18next';

type BaseFeedLayoutProps = {
  titleKey: string;
  textKey: string;
  image: ReactElement;
  variant?: 'default' | 'reverse';
  ns: string;
  titleVariant?: TypographyVariant;
  textVariant?: TypographyVariant;
  titleClassName?: string;
  textClassName?: string;
  colLeftClassName?: string;
  colRightClassName?: string;
} & BoxProps;

export const BaseFeedLayout: FC<BaseFeedLayoutProps> = ({
  variant = 'default',
  image,
  textKey,
  titleKey,
  ns,
  titleVariant = 'h2',
  textVariant = 'subtitle2',
  titleClassName,
  textClassName,
  colLeftClassName,
  colRightClassName,
  ...boxProps
}) => {
  return (
    <Box
      className={clsx(styles.root, boxProps?.className, {
        [styles.rootReverse]: variant === 'reverse',
      })}
    >
      <Box
        className={clsx(
          styles.item,
          styles.itemImage,
          colLeftClassName && { [colLeftClassName]: variant === 'default' },
          colRightClassName && { [colRightClassName]: variant === 'reverse' },
        )}
      >
        {image}
      </Box>
      <Box
        className={clsx(
          styles.item,
          colLeftClassName && { [colLeftClassName]: variant === 'reverse' },
          colRightClassName && { [colRightClassName]: variant === 'default' },
        )}
      >
        <Typography
          variant={titleVariant}
          className={clsx(styles.itemTitle, titleClassName)}
        >
          <Trans
            ns={ns}
            i18nKey={titleKey}
            components={{
              br: <br />,
            }}
          />
        </Typography>
        <Typography
          variant={textVariant}
          className={clsx(styles.itemText, textClassName)}
        >
          <Trans
            ns={ns}
            i18nKey={textKey}
            components={{
              br: <br />,
            }}
          />
        </Typography>
      </Box>
    </Box>
  );
};
