import { Box, BoxProps } from '@mui/material';
import styles from './styles.module.scss';
import { FC } from 'react';
import clsx from 'clsx';

const BACKGROUND_LINES = Array.from(Array(20).keys());

export const LineBackground: FC<BoxProps> = ({ ...props }) => {
  return (
    <Box {...props} className={clsx(styles.lineBackgroundRoot, props.className)}>
      {BACKGROUND_LINES.map((item) => (
        <Box className={styles.lineBackgroundCol} key={item} />
      ))}
    </Box>
  );
};
