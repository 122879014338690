import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { Container } from '@/components';
import styles from './styles.module.scss';
import Image from 'next/image';

type Props = {
  title: string;
  description: string;
  actions: React.ReactNode;
};

export const UnlimitedLookupsSection: FC<Props> = ({ title, description, actions }) => {
  return (
    <Box component="section" className={styles.section}>
      <Container className={styles.container}>
        <Box className={styles.content}>
          <Typography variant="h2" className={styles.title}>
            {title}
          </Typography>

          <Typography variant="body1" className={styles.description}>
            {description}
          </Typography>
          {actions}
        </Box>
        <Box className={styles.animationWrapper}>
          <Image
            src="/images/home/temp_unlimLookupsAnimationPreview.webp"
            alt=""
            width={600}
            height={380}
          />
        </Box>
      </Container>
    </Box>
  );
};
