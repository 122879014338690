import { AppBar, Box } from '@mui/material';
import React, { useContext } from 'react';
import Link from 'next/link';
import { HeaderContext } from './context';
import { Container } from '../Container';
import styles from './styles.module.scss';
import clsx from 'clsx';
import { AuthButtons, AuthButtonsMobile, HeaderNav } from './components';
import LogoGradient from '@/assets/icons/LogoGradient.svg';

export const Header: React.FC = () => {
  const { menuIsOpen, setMenuIsOpen } = useContext(HeaderContext);

  return (
    <AppBar className={styles.appBar} component="header" position="sticky" id="header">
      <Container className={styles.container}>
        <Link href="/" className={styles.logo}>
          <LogoGradient />
        </Link>
        <Box
          className={clsx(styles.menu, {
            [styles.menuIsOpen]: menuIsOpen,
          })}
        >
          <HeaderNav />
          <Box className={styles.appBarButtons}>
            <AuthButtons />
          </Box>
        </Box>
        <AuthButtonsMobile />
        <Box
          className={clsx('isMobile', styles.menuToggle, {
            [styles.menuIsOpen]: menuIsOpen,
          })}
          onClick={(): void => {
            setMenuIsOpen(!menuIsOpen);
          }}
        >
          <Box component="span"></Box>
          <Box component="span"></Box>
          <Box component="span"></Box>
        </Box>
      </Container>
    </AppBar>
  );
};
