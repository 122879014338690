import Cookies from 'universal-cookie';

interface ICookiesManager {
  cookies: Cookies;
  sharableAuthIdentityCookieName: string;
  getSharableAuthIdentityCookie(): ISharableAuthIdentity;
}

export interface ISharableAuthIdentity {
  domain: string;
  auth: boolean;
}

export interface ISharableUTM {
  id: string | null;
  source: string | null;
  medium: string | null;
  campaign: string | null;
  content: string | null;
  term: string | null;
}

export class CookiesManager implements ICookiesManager {
  private _cookies: Cookies;
  static sharableAuthIdentityCookieName = 'pwAuthIdentity';
  static sharableUTMCookieName = 'pwUTM';

  constructor() {
    this._cookies = new Cookies(null, {});
  }

  public get sharableAuthIdentityCookieName(): string {
    return CookiesManager.sharableAuthIdentityCookieName;
  }

  public get cookies(): Cookies {
    return this._cookies;
  }

  public setSharableUTMCookie(utm: ISharableUTM): void {
    const expireDate = new Date();
    expireDate.setDate(expireDate.getDate() + 1);

    this._cookies.set(CookiesManager.sharableUTMCookieName, JSON.stringify(utm), {
      domain: process.env.NEXT_PUBLIC_SUBDOMAIN,
      expires: expireDate,
    });
  }

  public getSharableAuthIdentityCookie(): ISharableAuthIdentity {
    return this.cookies.get(CookiesManager.sharableAuthIdentityCookieName);
  }
}

export type { ICookiesManager };
