import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

interface Props {
  onChange(token: string | null): void;
  className?: string;
}

export const Captcha: React.FC<Props> = ({ onChange, className }) => (
  <ReCAPTCHA
    className={className}
    sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_KEY as string}
    onChange={onChange}
  />
);
