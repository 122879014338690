import { Box, Typography } from '@mui/material';
import Image from 'next/image';
import { FC, ReactElement } from 'react';
import styles from './styles.module.scss';
import BracketsIcon from './assets/brackets.svg';
import type { BaseCarouselProps } from '../BaseCarousel';
import dynamic from 'next/dynamic';

const BaseCarousel = dynamic<BaseCarouselProps<FeedbackCarouselItem>>(
  () => import('../BaseCarousel').then((module) => module.BaseCarousel),
  {
    ssr: false,
    loading: () => <div className={styles.carouselLoader} />,
  },
);

type FeedbackCarouselItem = {
  id: string;
  text: string;
  userAvatar: string;
  userName: string;
  userPosition: string;
};

type FeedbackCarouselProps = {
  items: FeedbackCarouselItem[];
};

export const FeedbackCarousel: FC<FeedbackCarouselProps> = ({ items }) => {
  return (
    <BaseCarousel
      items={items}
      renderSlide={(item): ReactElement => {
        return (
          <Box className={styles.carouselItem}>
            <Box className={styles.carouselItemIcon}>
              <BracketsIcon />
            </Box>
            <Typography variant="subtitle2" className={styles.carouselItemText}>
              {item.text}
            </Typography>
            <Box className={styles.userInfo}>
              <Box className={styles.userAvatar}>
                <Image width={64} height={64} src={item.userAvatar} alt="" />
              </Box>
              <Box>
                <Typography className={styles.userName}>{item.userName}</Typography>
                <Typography className={styles.userPosition}>
                  {item.userPosition}
                </Typography>
              </Box>
            </Box>
          </Box>
        );
      }}
    />
  );
};
