import Script from 'next/script';
import { FC } from 'react';
import { taboolaID } from './config';

export const TaboolaPixel: FC<{ id?: number }> = ({ id = taboolaID }) => (
  <Script strategy="lazyOnload" id="taboola-pixel" type="text/javascript">
    {`window._tfa = window._tfa || [];
      window._tfa.push({notify: 'event', name: 'page_view', id: ${id}});
      !function (t, f, a, x) {
      if (!document.getElementById(x)) {
        t.async = 1;t.src = a;t.id=x;f.parentNode.insertBefore(t, f);
      }
      }(document.createElement('script'),
      document.getElementsByTagName('script')[0],
      '//cdn.taboola.com/libtrc/unip/${id}/tfa.js',
      'tb_tfa_script')`}
  </Script>
);
