import { useAuth } from '@/features/auth';
import { Box, Button } from '@mui/material';
import clsx from 'clsx';
import { FC } from 'react';
import styles from './styles.module.scss';
import { EXTERNAL_ROUTES } from '@/libs/routing';
import { useTranslation } from 'next-i18next';

export const AuthButtonsMobile: FC = () => {
  const { t } = useTranslation('common');
  const auth = useAuth();

  const buttonText = auth.isAuthenticated
    ? t('startFreeForm.buttonAuthenticated')
    : t('startFreeForm.buttonUnauthenticated');

  const buttonHref = auth.isAuthenticated
    ? EXTERNAL_ROUTES.DASHBOARD_SIGN_IN
    : EXTERNAL_ROUTES.DASHBOARD_SIGN_UP;

  return (
    <Box className={clsx(styles.authButtonsMobile)}>
      <Button color="secondary" href={buttonHref} variant="contained">
        {buttonText}
      </Button>
    </Box>
  );
};
