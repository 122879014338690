import React from 'react';
import styles from './styles.module.scss';
import clsx from 'clsx';

const ContainerBlue: React.FC<React.PropsWithChildren<{ className?: string }>> = ({
  children,
  className,
}) => {
  return <div className={clsx(styles.Container, className)}>{children}</div>;
};

export default ContainerBlue;
