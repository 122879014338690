import React, { useContext } from 'react';
import { HeaderContext } from '../../context';
import { Box, List } from '@mui/material';
import styles from './styles.module.scss';
import { HeaderNavItem } from '../HeaderNavItem/HeaderNavItem';

export const HeaderNav: React.FC = () => {
  const { config: navConfig } = useContext(HeaderContext);

  return (
    <Box component="nav" className={styles.nav}>
      <List className={styles.navList}>
        {navConfig.map((option) => {
          return <HeaderNavItem key={option.id} option={option} />;
        })}
      </List>
    </Box>
  );
};
