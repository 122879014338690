import Script from 'next/script';
import { FC, useEffect } from 'react';

declare global {
  interface Window {
    OptanonWrapper: () => void;
    OneTrust: {
      AllowAll: () => void;
    };
  }
}

export const OneTrust: FC = () => {
  useEffect(() => {
    setTimeout(() => {
      if (typeof window !== 'undefined' && window.OneTrust) {
        window.OneTrust.AllowAll();
      }
    }, 3000);
  }, []);

  return (
    <>
      <Script
        id="ot-sdk"
        strategy="lazyOnload"
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        type="text/javascript"
        data-domain-script="e3ecab90-caae-488c-9adf-14a17ddfb195-test"
      ></Script>
      <Script
        id="ot-start"
        strategy="lazyOnload"
        type="text/javascript"
      >{`function OptanonWrapper() {}`}</Script>
    </>
  );
};
