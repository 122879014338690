import { EXTERNAL_ROUTES } from './routes';

export const toDashboardLogin = (): void => {
  window.open(EXTERNAL_ROUTES.DASHBOARD_SIGN_IN, '_self');
};

export const toDashboardSignUp = (): void => {
  window.open(EXTERNAL_ROUTES.DASHBOARD_SIGN_UP, '_self');
};

export const toDashboardPricing = (): void => {
  window.open(EXTERNAL_ROUTES.DASHBOARD_PRICING, '_self');
};
