import { Box } from '@mui/material';
import { ReactElement, useState } from 'react';
import { Swiper, SwiperSlide, SwiperClass } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import styles from './styles.module.scss';
import clsx from 'clsx';
import { West, East } from '@mui/icons-material';

export type BaseCarouselProps<T> = {
  items: T[];
  renderSlide: (item: T) => ReactElement;
};

export const BaseCarousel = <T extends { id: string }>({
  items,
  renderSlide,
}: BaseCarouselProps<T>): ReactElement => {
  const [swiper, setSwiper] = useState<SwiperClass>();

  const handleNextCLick = (): void => {
    swiper?.slideNext();
  };

  const handlePrevCLick = (): void => {
    swiper?.slidePrev();
  };

  return (
    <>
      <Swiper
        pagination={{
          clickable: true,
          el: '.carousel-pagination',
        }}
        navigation={{
          prevEl: '.carousel-prev',
          nextEl: '.carousel-next',
        }}
        modules={[Pagination]}
        className={styles.root}
        onSwiper={(swiper: SwiperClass): void => {
          setSwiper(swiper);
        }}
        autoplay={{ delay: 500 }}
        loop
      >
        {items.map((item) => {
          return <SwiperSlide key={item.id}>{renderSlide(item)}</SwiperSlide>;
        })}
      </Swiper>
      <Box className={styles.nav}>
        <Box onClick={handlePrevCLick} className={clsx(styles.prevBtn, 'carousel-prev')}>
          <West />
        </Box>
        <Box className={clsx(styles.pagination, 'carousel-pagination')}></Box>
        <Box onClick={handleNextCLick} className={clsx(styles.nextBtn, 'carousel-next')}>
          <East />
        </Box>
      </Box>
    </>
  );
};
