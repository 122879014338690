import { FC } from 'react';
import { Trans } from 'next-i18next';
import { Box, Button, Typography } from '@mui/material';
import { useCookiePolicy } from '../hooks';
import styles from './styles.module.scss';

export const CookiePolicy: FC = () => {
  const { close, isShowCookiePolicyPopup } = useCookiePolicy();

  if (!isShowCookiePolicyPopup) {
    return;
  }

  return (
    <Box className={styles.container}>
      <Typography className={styles.description}>
        <Trans ns="common" i18nKey="cookies.description" components={{ b: <b /> }} />
      </Typography>
      <Box className={styles.actions}>
        <Button
          onClick={close}
          className={styles.submitButton}
          variant={'contained'}
          color={'primary'}
        >
          <Trans ns="common" i18nKey="cookies.buttonTitle" />
        </Button>
        <Button onClick={close} className={styles.close} variant={'text'}>
          <Trans ns="common" i18nKey="cookies.close" />
        </Button>
      </Box>
    </Box>
  );
};
