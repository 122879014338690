import { onCLS, onFID, onLCP } from 'web-vitals';

const reportWebVitals = (onPerfEntry: () => void): void => {
  if (typeof window !== 'undefined') {
    if (onPerfEntry && onPerfEntry instanceof Function) {
      onCLS(onPerfEntry);
      onFID(onPerfEntry);
      onLCP(onPerfEntry);
    }
  }
};

export default reportWebVitals;
