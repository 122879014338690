import Script from 'next/script';
import { FC } from 'react';
import { helpCenterID } from './config';

export const Intercom: FC<{ id?: string }> = ({ id = helpCenterID }) => (
  // eslint-disable-next-line @next/next/no-before-interactive-script-outside-document
  <Script strategy="lazyOnload" id="intercom" type="text/javascript">
    {`
      window.intercomSettings = {
        app_id: '${id}'
      };
      (function () {
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === 'function') {
          ic('reattach_activator');
          ic('update', w.intercomSettings);
        } else {
          var d = document;
          var i = function () {
            i.c(arguments);
          };
          i.q = [];
          i.c = function (args) {
            i.q.push(args);
          };
          w.Intercom = i;
          var l = function () {
            var s = d.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.src =
              'https://widget.intercom.io/widget/${id}';
            s.onload = function() {
              window?.Intercom?.('boot', {
                app_id: '${id}',
              });
            };
            var x = d.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s, x);
          };
          if (document.readyState === 'complete') {
            l();
          } else if (w.attachEvent) {
            w.attachEvent('onload', l);
          } else {
            w.addEventListener('load', l, false);
          }
        }
      })();
    `}
  </Script>
);
