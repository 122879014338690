import React from 'react';
import styles from './styles.module.scss';

interface Props {
  text: string;
}

export const FormError: React.FC<Props> = ({ text }) => (
  <div className={styles.error}>{text}</div>
);
