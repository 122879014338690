import Box, { BoxProps } from '@mui/material/Box';
import { FC, PropsWithChildren } from 'react';
import styles from './style.module.scss';
import clsx from 'clsx';

export const Container: FC<PropsWithChildren<BoxProps>> = ({
  children,
  ...restProps
}) => {
  return <Box className={clsx(styles.container, restProps.className)}>{children}</Box>;
};
