import React from 'react';

type Props = {
  text: string;
  lastLetterGradient?: boolean;
  className?: string;
};

export const getWithGradientedName = ({ text, lastLetterGradient }: Props): string => {
  let result = text.replaceAll(
    'Powerlead',
    '<span class="pw-text-gradient">Powerlead</span>',
  );

  if (lastLetterGradient) {
    const lastLetter = result.slice(-1);
    result = result.slice(0, -1) + `<span class="pw-text-gradient">${lastLetter}</span>`;
  }

  return result;
};

export const WithGradientedName: React.FC<Props> = ({
  text,
  lastLetterGradient = false,
  className,
}) => {
  return (
    <span
      className={className}
      dangerouslySetInnerHTML={{
        __html: getWithGradientedName({ text, lastLetterGradient }),
      }}
    />
  );
};
