import { appWithTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import { FC } from 'react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { createEmotionCache, theme } from '@/libs/theming';
import reportWebVitals from '@/libs/reportWebVitals';
import { AppLayout, CookiePolicyProvider } from '@/components';
import { AuthProvider } from '@/features/auth';
import { LeadsTracker } from '@/features/leadsTracking';
import { FacebookPixel } from '@/libs/analytics/Facebook';
import { GoogleTagManager } from '@/libs/analytics/GoogleTagManager';
import { HubspotPixel } from '@/libs/analytics/Hubspot';
import packageJSON from '../../package.json';
import '../styles/global.scss';
import { RB2B } from '@/libs/analytics/RB2B';
import { TaboolaPixel } from '@/libs/analytics/Taboola';
import { OneTrust } from '@/libs/OneTrust';
import { Intercom } from '@/libs/Intercom/Intercom';
import { GA } from '@/libs/analytics/GA';

const clientSideEmotionCache = createEmotionCache();

export interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

const App: FC<MyAppProps> = ({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
}) => {
  return (
    <>
      <CacheProvider value={emotionCache}>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <LeadsTracker />
            <CookiePolicyProvider>
              <CssBaseline />
              <AppLayout>
                <Component {...pageProps} />
              </AppLayout>
            </CookiePolicyProvider>
          </AuthProvider>
        </ThemeProvider>
      </CacheProvider>
      <GoogleTagManager gtmId={process.env.NEXT_PUBLIC_GTM_ID as string} />
      <GA />
      <FacebookPixel />
      <HubspotPixel />
      <TaboolaPixel />
      <OneTrust />
      <RB2B />
      <OneTrust />
      <Intercom />
    </>
  );
};

export default appWithTranslation(App);

reportWebVitals(console.log);

console.log(
  `%cCurrent Version: ${packageJSON.version}`,
  'color: blue; font-size:30px; font-weight:bold',
);
