import { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { CookiePolicyContext } from '../contexts';

const HAVE_READ_COOKIE_POLICY_KEY = 'haveReadCookiePolicy';

export const CookiePolicyProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isShowCookiePolicyPopup, setIsShowCookiePolicyPopup] = useState(false);

  useEffect(() => {
    const syncCookiePolicy = (value: string | null): void => {
      try {
        const haveReadCookiePolicy = value ? JSON.parse(value) : false;
        setIsShowCookiePolicyPopup(!haveReadCookiePolicy);
      } catch {
        setIsShowCookiePolicyPopup(false);
      }
    };

    const handleStorageChange = (e: StorageEvent): void => {
      if (e.key === HAVE_READ_COOKIE_POLICY_KEY) {
        syncCookiePolicy(e.newValue);
      }
    };

    syncCookiePolicy(localStorage.getItem(HAVE_READ_COOKIE_POLICY_KEY));

    window.addEventListener('storage', handleStorageChange);

    return (): void => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const contextValue = useMemo(() => {
    return {
      isShowCookiePolicyPopup,
      show(): void {
        setIsShowCookiePolicyPopup(true);
      },
      close(): void {
        setIsShowCookiePolicyPopup(false);
        localStorage.setItem(HAVE_READ_COOKIE_POLICY_KEY, JSON.stringify(true));
      },
      openOneTrustDialog(): void {
        // @ts-ignore
        if (window.OneTrust) {
          // @ts-ignore
          window.OneTrust.ToggleInfoDisplay();
        }
      },
    };
  }, [isShowCookiePolicyPopup]);

  return (
    <CookiePolicyContext.Provider value={contextValue}>
      {children}
    </CookiePolicyContext.Provider>
  );
};
