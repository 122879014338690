import React from 'react';
import styles from './styles.module.scss';
import { Box, Input, InputProps } from '@mui/material';
import { FormError } from '@/components';

interface Props extends InputProps {
  errorMessage?: string;
}

const FormInput: React.FC<Props> = ({ error, errorMessage, ...props }) => (
  <Box>
    <Input
      {...props}
      error={error}
      classes={{ input: styles.Input, root: styles.Root }}
      type="text"
    />
    {error && !!errorMessage && <FormError text={errorMessage} />}
  </Box>
);

export default FormInput;
