import { CookiesManager, ICookiesManager, ISharableAuthIdentity } from '@/libs/cookies';
import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { AuthContext } from '../contexts';

function validateSharableAuthIdentityCookie(
  authIdentity: ISharableAuthIdentity,
): boolean {
  if (typeof window === 'undefined' || !authIdentity) return false;

  return (
    authIdentity.domain === window.location.origin.replace('www.', '') &&
    authIdentity.auth
  );
}

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [contextValue, setContextValue] = useState({
    isAuthenticated: false,
  });

  const [cookieManager] = useState<ICookiesManager>(() => new CookiesManager());

  useEffect(() => {
    const updateContext = (isAuthenticated: boolean): void => {
      setContextValue((prev) => {
        if (prev.isAuthenticated === isAuthenticated) return prev;

        return {
          ...prev,
          isAuthenticated,
        };
      });
    };

    const handleCookiesChange = ({ name }: { name: string }): void => {
      if (name === cookieManager.sharableAuthIdentityCookieName) {
        const isAuthenticated = validateSharableAuthIdentityCookie(
          cookieManager.getSharableAuthIdentityCookie(),
        );
        updateContext(isAuthenticated);
      }
    };

    const isAuthenticated = validateSharableAuthIdentityCookie(
      cookieManager.getSharableAuthIdentityCookie(),
    );

    updateContext(isAuthenticated);

    cookieManager.cookies.addChangeListener(handleCookiesChange);

    return (): void => {
      cookieManager.cookies.removeChangeListener(handleCookiesChange);
    };
  }, [cookieManager]);

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};
