import { createTheme } from '@mui/material/styles';
import { circularStd } from '@/libs/fonts';
import palette from '@/styles/palette.module.scss';
import { MuiButton } from './overrides';

const toRem = (val: number): number => val / 16;

export const theme = createTheme({
  components: {
    MuiButton,
  },
  typography: {
    fontFamily: circularStd.style.fontFamily,
    htmlFontSize: 14,
    h1: {
      fontSize: `${toRem(64)}rem`,
      fontWeight: 600,
    },
    h2: {
      fontSize: `${toRem(48)}rem`,
      fontWeight: 600,
      lineHeight: 1.2,
    },
    h3: {
      fontSize: `${toRem(27)}rem`,
      fontWeight: 600,
      lineHeight: 1.2,
    },
    h4: {
      fontSize: `${toRem(24)}rem`,
      fontWeight: 600,
      lineHeight: 1.2,
    },
    h5: {
      fontSize: `${toRem(22)}rem`,
      fontWeight: 600,
      lineHeight: 1.2,
    },
    subtitle1: {
      fontSize: `${toRem(22)}rem`,
      lineHeight: 1.4,
    },
    subtitle2: {
      fontSize: `${toRem(20)}rem`,
      lineHeight: 1.4,
    },
    body1: {
      fontSize: `${toRem(18)}rem`,
      lineHeight: 1.4,
    },
    body2: {
      fontSize: `${toRem(14)}rem`,
      lineHeight: 1.4,
    },
  },
  palette: {
    primary: {
      light: palette.blue300,
      main: palette.blue500,
      dark: palette.blue700,
    },
    secondary: {
      light: palette.orange300,
      main: palette.orange500,
      dark: palette.orange700,
    },
    info: {
      light: palette.grey300,
      main: palette.grey500,
      dark: palette.grey700,
    },
    text: {
      primary: palette.grey900,
      secondary: palette.grey500,
    },
    error: {
      light: palette.red200,
      main: palette.red500,
    },
    common: {
      white: palette.white,
      black: palette.black,
    },
  },
});
