import { FC, PropsWithChildren } from 'react';
import { clsx } from 'clsx';
import { Box } from '@mui/material';
import { circularStd } from '@/libs/fonts';
import { Header, HeaderProvider, Footer } from '@/components';

import styles from './styles.module.scss';

export const AppLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box className={clsx(styles.root, circularStd.className)}>
      <HeaderProvider>
        <Header />
      </HeaderProvider>
      <Box component="main" className={styles.main}>
        {children}
      </Box>
      <Footer />
    </Box>
  );
};
