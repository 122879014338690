import { Box, List, ListItem, Typography } from '@mui/material';
import React from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'next-i18next';
import { Container } from '../Container';
import { WithGradientedName } from '../WithGradientedName';
import ListMarkerIcon from '@/assets/icons/GradientedListMarker.svg';
import Image from 'next/image';
import Link from 'next/link';
import { EXTERNAL_ROUTES } from '@/libs/routing';

export const ExtensionPromoSection: React.FC = () => {
  const { t } = useTranslation('common', { keyPrefix: 'extensionPromo' });

  return (
    <Box component="section" className={styles.section}>
      <Container>
        <Typography variant="h2" className={styles.title}>
          <WithGradientedName text={t('title')} lastLetterGradient />
        </Typography>
        <Box className={styles.contentWrapper}>
          <Box>
            <Typography variant="h4" className={styles.listTitle}>
              {t('listTitle')}
            </Typography>
            <List>
              {Array.from({ length: 3 }, (_, i) => {
                return (
                  <ListItem key={i} className={styles.listItem}>
                    <ListMarkerIcon />
                    {t(`listItem${i + 1}`)}
                  </ListItem>
                );
              })}
            </List>
            <Link
              target="_blank"
              href={EXTERNAL_ROUTES.EXTENSION_MARKET}
              className={styles.link}
            >
              <Image src="/images/common/extensionDownloadImg.webp" alt="" fill />
            </Link>
          </Box>
          <Box className={styles.imagePart}>
            <Box className={styles.imageWrapper}>
              <Image src="/images/common/extensionPromoImg.webp" alt="" fill />
            </Box>
            <Box>
              {Array.from({ length: 5 }, (_, i) => {
                return <Box key={i} className={styles.circle} />;
              })}
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
