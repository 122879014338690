import { FC, ReactElement } from 'react';
import clsx from 'clsx';
import { ArrowForward, East } from '@mui/icons-material';
import Link, { LinkProps } from 'next/link';
import { Button } from '@mui/material';

import styles from './styles.module.scss';

export const ArrowLink: FC<
  LinkProps & {
    text: string | ReactElement;
    className?: string;
    variant?: 'default' | 'black';
  }
> = ({ text, variant = 'default', href, ...props }) => {
  if (variant === 'black' && href) {
    return (
      <Button
        component={Link}
        // eslint-disable-next-line @typescript-eslint/no-base-to-string
        href={href.toString()}
        className={clsx(styles.black, props.className)}
        disableRipple
      >
        <span>
          {text}
          {/* @ts-ignore  */}
          <East fontSize="10px" />
        </span>
      </Button>
    );
  }

  return (
    <Link href={href} {...props} className={clsx(styles.link, props?.className)}>
      {text}
      <ArrowForward />
    </Link>
  );
};
