import { useEffect, useState } from 'react';

type UtmData = {
  utm_source: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_term?: string;
};

export const useUtmData = (): UtmData | null => {
  const [utmData, setUtmData] = useState<UtmData | null>(null);

  useEffect(() => {
    const data = localStorage.getItem('utm_data');

    if (data) {
      setUtmData(JSON.parse(data) as UtmData);
    }
  }, []);

  return utmData;
};
