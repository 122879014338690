import { Box, Button } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'next-i18next';
import { EXTERNAL_ROUTES } from '@/libs/routing';
import { useAuth } from '@/features/auth';
import { useAnalytics } from '@/libs/analytics';
import Link from 'next/link';
import styles from './styles.module.scss';
import { East } from '@mui/icons-material';

type SectionButtonsProps = {
  learmMoreHref?: string;
};

export const SectionButtons: FC<SectionButtonsProps> = ({ learmMoreHref }) => {
  const { t } = useTranslation('common', { keyPrefix: 'sectionButtons' });
  const auth = useAuth();
  const { startForFreeClicked } = useAnalytics();
  return (
    <Box className={styles.buttons}>
      <Button
        variant="contained"
        component={Link}
        href={
          auth.isAuthenticated
            ? EXTERNAL_ROUTES.DASHBOARD_SIGN_IN
            : EXTERNAL_ROUTES.DASHBOARD_SIGN_UP
        }
        color="secondary"
        onClick={startForFreeClicked}
      >
        {auth.isAuthenticated ? t('authenticated') : t('unauthenticated')}
      </Button>
      <Button
        component={Link}
        href={learmMoreHref}
        className={styles.learnMore}
        disableRipple
      >
        <span>
          {t('learn')}
          {/* @ts-ignore */}
          <East fontSize="10px" />
        </span>
      </Button>
    </Box>
  );
};
