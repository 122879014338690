import Script from 'next/script';
import { FC } from 'react';
import { gaID } from './config';

export const GA: FC<{ id?: string }> = ({ id = gaID }) => (
  <Script strategy="lazyOnload" id={'ga-init'}>
    {`
                     window.dataLayer = window.dataLayer || [];
                     function gtag(){dataLayer.push(arguments);}
                     gtag('js', new Date());
                     gtag('config', '${id}', {
                     page_path: window.location.pathname,
                     });
                 `}
  </Script>
);
