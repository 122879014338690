import { Container } from '@/components';
import { Box, BoxProps, Typography } from '@mui/material';
import { FC } from 'react';
import StarIcon from '@/assets/icons/Star.svg';
import BracketsIcon from './assets/brackets.svg';
import ArrowIcon from './assets/arrow.svg';
import styles from './styles.module.scss';
import Image from 'next/image';
import clsx from 'clsx';

type BaseFeedbackSectionProp = {
  title?: string;
  text: string;
  userAvatar: string;
  userName: string;
  userRole: string;
  bodyClassName?: string;
} & BoxProps;

export const BaseFeedbackSection: FC<BaseFeedbackSectionProp> = ({
  title,
  text,
  userAvatar,
  userName,
  userRole,
  bodyClassName,
  ...boxProps
}) => {
  return (
    <Box
      {...boxProps}
      className={clsx(styles.section, boxProps.className)}
      component="section"
    >
      <Container>
        <Box className={clsx(styles.body, bodyClassName)}>
          {title && (
            <>
              <Typography variant="h2" className={styles.title}>
                {title}
              </Typography>
              <Box className={clsx(styles.arrowIcon, 'isDesktop')}>
                <ArrowIcon />
              </Box>
            </>
          )}
          <Box className={styles.feedbackItem}>
            <Box className={styles.feedbackItemAvatar}>
              <Image src={userAvatar} alt="" width={96} height={96} />
            </Box>
            <Box className={styles.feedbackItemContainer}>
              <Box className={styles.feedbackItemParagraphIcon}>
                <BracketsIcon />
              </Box>
              <Typography variant="subtitle2" className={styles.feedbackItemText}>
                {text}
              </Typography>
              <Box className={styles.feedbackItemPersonalInfo}>
                <Typography variant="body1" className={styles.feedbackItemUserName}>
                  {userName}
                </Typography>
                <Typography variant="body2" className={styles.feedbackItemUserPosition}>
                  {userRole}
                </Typography>
                <Box className={styles.feedbackItemRating}>
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
