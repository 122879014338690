import { FC, ReactElement, useRef } from 'react';
import { Box, BoxProps, Button, TextField, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { Controller } from 'react-hook-form';
import clsx from 'clsx';
import CheckIcon from '@/assets/icons/GradientedListMarker.svg';
import styles from './styles.module.scss';
import { useStartFreeForm } from '@/components/StartFreeForm/hooks';
import { FormError } from '../FormError';
import { useAuth } from '@/features/auth';
import { EXTERNAL_ROUTES, ROUTES } from '@/libs/routing';
import Link from 'next/link';

type StartFreeFormProps = {
  showCompliantInfo?: boolean;
  skipAuthView?: boolean;
} & BoxProps;

export const StartFreeForm: FC<StartFreeFormProps> = ({
  showCompliantInfo = false,
  skipAuthView = false,
  ...props
}) => {
  const { t } = useTranslation();
  const { control, errors, handleSubmit } = useStartFreeForm();
  const emailInputRef = useRef<HTMLInputElement>(null);
  const auth = useAuth();

  if (auth.isAuthenticated && !skipAuthView) {
    return (
      <Box className={styles.notion}>
        <Button
          component={Link}
          href={EXTERNAL_ROUTES.DASHBOARD}
          variant="contained"
          color="secondary"
        >
          {t('startFreeForm.buttonAuthenticated')}
        </Button>
        <Button
          component={Link}
          href={ROUTES.CONTACT_US}
          variant="outlined"
          color="secondary"
        >
          {t('startFreeForm.buttonContactSales')}
        </Button>
      </Box>
    );
  }

  if (!auth.isAuthenticated) {
    return (
      <>
        <Box
          {...props}
          component="form"
          className={clsx(styles.form, props.className)}
          onSubmit={handleSubmit}
        >
          <Controller
            control={control}
            name={'email'}
            render={({ field }): ReactElement => (
              <TextField
                {...field}
                className={clsx(styles.textField, {
                  [styles.textFieldError]: !!errors.email,
                })}
                inputRef={emailInputRef}
                InputProps={{
                  classes: {
                    root: clsx(styles.textFieldInput, {
                      [styles.textFieldInputError]: !!errors.email,
                    }),
                  },
                  placeholder: t('startFreeForm.placeholder'),
                  endAdornment: (
                    <Button
                      type="submit"
                      variant="contained"
                      color="secondary"
                      className={clsx(styles.textFieldButton, 'isDesktop')}
                    >
                      {t('startFreeForm.buttonUnauthenticated')}
                    </Button>
                  ),
                }}
              />
            )}
          />

          <Button
            type="submit"
            variant="contained"
            color="secondary"
            className={clsx(styles.textFieldButton, 'isMobile')}
          >
            {t('startFreeForm.buttonUnauthenticated')}
          </Button>
          {!!errors.email && <FormError text={errors.email?.message as string} />}
          <Box className={styles.disclaimerWrapper}>
            {showCompliantInfo && (
              <>
                <Box className={clsx(styles.disclaimerItem, styles.disclaimerItemGdpr)}>
                  <CheckIcon />
                  <Typography variant="body2">{t('startFreeForm.gdpr')}</Typography>
                </Box>
                <Box className={clsx(styles.disclaimerItem, styles.disclaimerItemCcpa)}>
                  <CheckIcon />
                  <Typography variant="body2">{t('startFreeForm.ccpa')}</Typography>
                </Box>
              </>
            )}
            <Box className={clsx(styles.disclaimerItem, styles.disclaimerItemDefault)}>
              <Typography variant="body2">{t('startFreeForm.noCard')}</Typography>
            </Box>
          </Box>
        </Box>
      </>
    );
  }

  return null;
};
