// @ts-nocheck
import { Components } from '@mui/material';

export const MuiButton: Components['MuiButton'] = {
  styleOverrides: {
    root: (): Record<string, unknown> => ({
      textTransform: 'initial',
      fontSize: '0.875rem',
      minWidth: 'initial',
      borderRadius: '0.375rem',
      padding: '0.5rem 1.25rem',
      lineHeight: '1.3',
      boxShadow: 'none',
      '@media screen and (max-width: 767px)': {
        fontSize: '1.125rem',
      },
    }),
  },
  variants: [
    {
      props: { variant: 'contained', color: 'secondary' },
      style: {
        backgroundImage: 'var(--pw-gradient)',
        padding: '12px 26px',
      },
    },
    {
      props: { variant: 'outlined', color: 'secondary' },
      style: {
        border: 'solid 1px #fe8023',
      },
    },
  ],
};
