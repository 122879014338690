import { FC, ReactElement, ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
import { Container } from '../Container';
import { StartFreeForm } from '@/components';
import styles from './styles.module.scss';
import clsx from 'clsx';

type HeadSectionProps = {
  background?: ReactElement;
  title: ReactNode;
  subtitle: ReactNode;
  image: ReactElement;
};

export const HeadSection: FC<HeadSectionProps> = ({
  title,
  subtitle,
  image,
  background,
}) => {
  return (
    <Box component="section" className={styles.section}>
      <Box className={styles.background}>{background}</Box>
      <Container className={styles.container}>
        <Box className={clsx(styles.body)}>
          <Typography variant="h1" className={styles.title}>
            {title}
          </Typography>
          <Typography variant="h5" className={styles.subtitle}>
            {subtitle}
          </Typography>
          <StartFreeForm showCompliantInfo className={styles.form} />
        </Box>
        <Box className={styles.image}>{image}</Box>
      </Container>
    </Box>
  );
};
