export const AMPLITUDE_EVENTS = {
  START_FOR_FREE_CLICKED: 'StartForFree_Clicked',
  REQUEST_DEMO_CLICKED: 'RequestDemo_Clicked',
  CHROME_EXTENSION_CLICKED: 'ChromeExtension_Clicked',
  PRICING_PAGE: {
    MONTHLY_CLICKED: 'Monthly_Clicked',
    ANNUAL_CLICKED: 'Annual_Clicked',
    GET_STARTED_CLICKED: 'GetStarted_Clicked',
    TALK_TO_SALES_CLICKED: 'TalkToSales_Clicked',
  },
  BOOK_DEMO_CLICKED: 'BookDemo_Clicked',
};
