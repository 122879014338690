import { CookiesManager } from '@/libs/cookies';
import { FC, useEffect, useState } from 'react';

export const LeadsTracker: FC = () => {
  const [cookiesManager] = useState(() => new CookiesManager());

  useEffect(() => {
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;

    const id = searchParams.get('utm_id');
    const source = searchParams.get('utm_source');
    const medium = searchParams.get('utm_medium');
    const campaign = searchParams.get('utm_campaign');
    const content = searchParams.get('utm_content');
    const term = searchParams.get('utm_term');

    const utmData = { source, medium, campaign, content, term };

    localStorage.setItem('utm_data', JSON.stringify(utmData));

    const utm = {
      id,
      source,
      medium,
      campaign,
      content,
      term,
    };

    if (!Object.values(utm).some((utmParam) => Boolean(utmParam))) {
      return;
    }

    cookiesManager.setSharableUTMCookie(utm);
  }, [cookiesManager]);

  return null;
};
