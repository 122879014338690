declare global {
  interface Window {
    fbq: (...args: unknown[]) => void;
  }
}

export const fbq = (...args: unknown[]): void => {
  if (window.fbq) {
    window.fbq(...args);
  }
};
